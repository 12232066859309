import { parseISO, differenceInMinutes } from 'date-fns';
import { businessService } from '@planity/helpers';
import { isNumber } from 'lodash';

const DEFAULT_DELAYS_CONFIG = {
	soonest: 60 * 24 * 30,
	latest: 0,
	cancellation: 0
};

/**
 * Creates a new delay configuration by comparing and updating with new delays.
 *
 * soonest => "A client can book an appointment online starting from (x) min before the appointment"
 * latest => "A client can book an appointment online up to (x) min before the appointment"
 * cancellation => "A client can cancel their appointment online up to (x) min before the appointment"
 *
 * @param {Object} delaysConfig - Current delay configuration.
 * @param {Object} newDelays - New delays to compare and update.
 * @returns {Object} Updated delay configuration.
 */
const applyRestrictiveDelays = (delaysConfig, newDelays) => {
	const updatedConfig = { ...delaysConfig };

	// Apply min soonest
	if (
		isNumber(newDelays.soonest) &&
		(delaysConfig.soonest === null || newDelays.soonest < delaysConfig.soonest)
	)
		updatedConfig.soonest = newDelays.soonest;

	// Apply max latest
	if (
		isNumber(newDelays.latest) &&
		(delaysConfig.latest === null || newDelays.latest > delaysConfig.latest)
	)
		updatedConfig.latest = newDelays.latest;

	// Apply max cancellation
	if (
		isNumber(newDelays.cancellation) &&
		(delaysConfig.cancellation === null ||
			newDelays.cancellation > delaysConfig.cancellation)
	)
		updatedConfig.cancellation = newDelays.cancellation;

	return updatedConfig;
};

/**
 * Calculates delay configuration based on sequence and business settings.
 * @param {Array<Object>} sequence - Array of step objects with serviceIds.
 * @param {Object} business - Business object with settings and services.
 * @returns {Object} Calculated delay configuration.
 */
export const getDelaysConfig = (sequence, business) => {
	const businessDelaysConfig = {
		...DEFAULT_DELAYS_CONFIG,
		...business?.settings?.delays
	};

	if (!sequence.length) return businessDelaysConfig;

	let delaysConfig = {
		soonest: null,
		latest: null,
		cancellation: null
	};

	for (const { serviceOrigin, serviceId } of sequence) {
		// If no delays are configured for the service, inherit delays from business.
		// If complexe service, we retrive delays from serviceOrigin (id {string})
		const service = businessService(business, serviceOrigin || serviceId);
		const serviceDelays = { ...businessDelaysConfig, ...service?.delays };

		delaysConfig = applyRestrictiveDelays(delaysConfig, serviceDelays);
	}
	return delaysConfig;
};

/**
 * Determines if a business allows cancellation of an appointment based on its cancellation policy.
 * @param {Object} appointment - The appointment object with a start date in ISO format and a sequence.
 * @param {Object} business - The business object with a time zone.
 * @returns {boolean} True if cancellation is allowed, false otherwise.
 */
export const checkCancellationPolicy = (appointment, business) => {
	const { cancellation: computedCancellationDelay } = getDelaysConfig(
		appointment.sequence,
		business
	);

	if (!computedCancellationDelay) return true;

	const minutesUntilAppointment = differenceInMinutes(
		parseISO(appointment.start),
		new Date()
	);

	return minutesUntilAppointment > computedCancellationDelay;
};
